<template>
  <img v-if="!src"
    :src="fallback"
    :alt="alt"
    :style="videoCss"
    :class="videoClass"
    style="object-fit:cover"
  />
  <video id="video-src" v-else :src="src" uk-video="autoplay: false" controls=true :style="videoCss"> </video>
</template>

<script>
import FallbackSrc from '@/assets/no-video.png';

export default {
  name: 'VideoOrFallback',
  props: {
    fallback: {
      type: String,
      default: FallbackSrc
    },
    src: {
      type: String,
      required: false
    },
    alt: {
      type: String,
      default: 'Fallback'
    },
    height: {
      type: String,
      default: '150px'
    },
    width: {
      type: String,
      default: '250px'
    },
    videoClass: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
    };
  },
  computed: {
    videoCss() {
      return {
        height: this.height,
        width: this.width,
      };
    }
  },
  watch: {
    src () {
      if (!this.src) {
        const vidElement = document.getElementById('video-src');
        if (vidElement) {
          vidElement.removeAttribute('src');
          vidElement.load();
        }
      }    
    }
  }
}
</script>