<template>
  <div v-if="!isAdBlockerEnabled">
    <div class="uk-flex uk-flex-between" >
      <div>
        <h1 class="uk-text-left uk-margin-remove-bottom">{{ $t('Sidebar.Advertising') }}</h1>
      </div>
      <div class="uk-flex">

        <div class="uk-margin-small-right">
          <form class="uk-search uk-width-auto@m uk-search-default" @submit.prevent="">
              <span class="uk-search-icon" uk-search-icon></span>
              <a v-if="searchKey" class="uk-form-icon uk-form-icon-flip" uk-icon="icon: close" @click.prevent="clearSearch()"></a>
              <input @blur="updateSearchKeyInURL()" class="uk-search-input uk-width-medium" type="search" v-model.trim="searchKey" :placeholder="$t('Generic.Labels.Search')">
          </form>
        </div>

        <div>
          <div class="uk-margin-small-right uk-align-right uk-margin-remove-bottom uk-margin-remove-left">
            <button class="uk-button uk-button-primary custom-button-padding uk-align-right uk-margin-remove-left uk-width-auto new-btn" @click.prevent="createNewAd()" type="button">
              <span class="uk-margin-small-right uk-padding-remove" uk-icon="plus-circle"></span> {{$t('Pages.Advertising.Labels.NewAd')}}
            </button>
          </div>

        </div>

        <div>
          <button :uk-tooltip="$t('Generic.Labels.Filter')" :aria-label="$t('Generic.Labels.Filter')" class="uk-button uk-button-primary uk-align-center uk-float-right uk-margin-small-right" style="padding-left: 10px; padding-right: 10px;">
            <img src="@/assets/filter-icon.svg" :alt="$t('Generic.Labels.Filter')" uk-svg />
          </button>
          <div style="min-width: 150px;" uk-dropdown="mode: hover; pos: bottom-left; animation: uk-animation-slide-top-small; duration: 500; delay-hide: 200">
            <ul class="uk-nav uk-dropdown-nav uk-text-left">
              <li class="uk-text-default" :class="filterClass.all" @click="changeFilter('all')"><a href="#">{{$t('Generic.Labels.All')}}</a></li>
              <li class="uk-text-default" :class="filterClass.video"><a @click="changeFilter('video')">{{$t('Pages.Advertising.AdTypes.video')}}</a></li>
              <li class="uk-text-default" :class="filterClass.medium_banner"><a @click="changeFilter('medium_banner')">{{$t('Pages.Advertising.AdTypes.MEDIUM_BANNER')}}</a></li>
              <li class="uk-text-default" :class="filterClass.small_banner"><a @click="changeFilter('small_banner')">{{$t('Pages.Advertising.AdTypes.SMALL_BANNER')}}</a></li>
              <li class="uk-text-default" :class="filterClass.promoted"><a @click="changeFilter('promoted')">{{$t('Pages.Advertising.AdTypes.promoted')}}</a></li>
            </ul>
          </div>

        </div>
        
      </div>
    </div>
    <hr class="uk-margin-remove-top" />
    <vue-good-table
      styleClass="vgt-table uk-table uk-table-small uk-table-striped uk-table-hover uk-table-responsive uk-margin-remove-bottom"
      :columns="tableHeaders"
      :sort-options="{
      enabled: true,
      initialSortBy: {field: 'name', type: 'asc'}
      }"
      v-if="ads && ads.length"
      :rows="formattedFilteredAds"
      :line-numbers="false"
      :pagination-options="{
        enabled: true,
        mode: 'pages',
        perPage: 10,
        position: 'bottom',
        perPageDropdown: [10, 25, 50, 100],
        dropdownAllowAll: true,
        setCurrentPage: 1,
        nextLabel: this.$t('Pages.PushNotifications.Next'),
        prevLabel: this.$t('Pages.PushNotifications.Prev'),
        rowsPerPageLabel: this.$t('Pages.PushNotifications.RowsPerPage'),
        ofLabel: 'of',
        pageLabel: this.$t('Pages.PushNotifications.Page'), // for 'pages' mode
        allLabel: this.$t('Pages.PushNotifications.All'),
      }"
    >
      <div slot="emptystate" class="vgt-center-align vgt-text-disabled">
        {{ $t( 'Pages.Advertising.NoSearchResult' ) }}
      </div>
      <template slot="table-row" slot-scope="record">
        <span v-if="record.column.field === 'icon'">
          <div v-if="record.row.icon" class="uk-cover-container uk-border-rounded" style="height: 48px; width: 85px;"> 
            <ImageOrAbbrevation
              :src="record.row.icon === 'NA' ? '' : record.row.icon"
              :alt="record.row.name"
              :width="width"
              :height="height"
            />
          </div>
          <div v-else class="uk-placeholder uk-border-rounded" style="padding: 25px;">
          </div>
        </span>
        <span v-else-if="record.column .field === 'linkedContent'">
          <a v-if="record.row.linkedContent && record.row.linkedContent.length" @click="showLinkedContents(record.row.linkedContent, record.row.title)">
            {{`${record.row.linkedContent.length} ${$t('Pages.Advertising.LinkedContents')} `}}
          </a>
          <span v-else>{{$t('Pages.Advertising.NoLinkedContents')}}</span>
        </span>
        <span v-else-if="record.column.field === 'actions'">
         <a uk-icon="icon: pencil; ratio: 1;" class="uk-margin-right" @click.prevent="editAd(record.row)"></a>
          <span v-if="deletingList.includes( record.row )" uk-spinner="ratio: 0.7" />
          <a v-else uk-icon="icon: trash; ratio: 1;" @click.prevent="deleteAd( record.row )"></a>
        </span>
        <span v-else-if="record.column.field === 'created'">
          {{$d(new Date(record.row.created), 'long')}}
        </span>
        <span v-else-if="record.column.field === 'lastUpdated'">
          {{$d(new Date(record.row.lastUpdated), 'long')}}
        </span>
      </template>
    </vue-good-table>
    <div v-if="fetching && !ads.length">
      <span uk-spinner></span>
    </div>
    <div v-else-if="!ads.length && !fetching && !searchKey">
      <span class="uk-text-meta">{{$t('Pages.Advertising.NoData')}}</span>
      <a class="uk-text-primary" @click="createNewAd()">{{$t('Pages.Advertising.Labels.NewAd')}}</a>
    </div>

    <div id="info-ad-modal" uk-modal="bg-close: false; esc-close: false;">
      <div class="uk-modal-dialog uk-modal-body">
        <button class="uk-modal-close-default" type="button" uk-close></button>
          <div class="uk-text-warning" uk-icon="icon: info; ratio: 2" />
        <p class="uk-text-meta">{{ cannotDeleteAdMessage }}</p>
      </div>
    </div>
    <prepare-ad-modal :adType=adType :isUpdate=isUpdate :adId=adId />
    <linked-contents-modal :linkedContents=linkedContentsToShow :title=titleToShow :orgId=orgId />
  </div>

</template>

<script>
import { mapState } from 'vuex';
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table';
import ImageOrAbbrevation from '@/components/images/ImageOrAbbrevation.vue';
import Notification from '@/components/util/Notification';
import PrepareAdModal from '@/components/modals/PrepareAdModal.vue';
import { isAdBlocker } from '@/utils/adBlocker';
import LinkedContentsModal from '@/components/modals/LinkedContentsModal.vue'

export default {
  name: 'AdvertisingView',
  components: {
    VueGoodTable,
    ImageOrAbbrevation,
    PrepareAdModal,
    LinkedContentsModal
  },
  data() {
    return {
      firstFetch: false,
      height: '50px',
      width: '90px',
      fetching: false,
      searchKey: this.$route?.query?.search || '',
      deletingList: [],
      cannotDeleteAdMessage: '',
      filterType: 'all',
      filterClass: {
        all: 'uk-active uk-text-bold',
        video: '',
        medium_banner: '',
        small_banner: '',
        promoted: ''
      },
      isAdBlockerEnabled: false,
      adTypes: ['video', 'small-banner', 'medium-banner', 'promoted'],
      adType: 'video',
      isUpdate: '',
      adId: '',
      orgId: this.$route.params.id,
      linkedContentsToShow: [],
      titleToShow: ''
    }
  },
  computed: {
    ...mapState({
      ads: state => state.venom?.org?.ads?.nodes || [],
      pageInfo: state => state.venom.org?.ads?.pageInfo || {}
    }),
    tableHeaders () {
      return [{
        label: this.$t('Pages.Advertising.Labels.Keyart'),
        field: 'icon',
        width: "9%",
        sortable: false
      },
      {
        label: this.$t('Generic.Labels.Name'),
        field: 'name',
        thClass: 'uk-width-large uk-preserve-width',
        tdClass: 'uk-width-large uk-text-middle uk-text-truncate',
        width: "30%",
      },
      {
        label: this.$t('Pages.Advertising.Labels.AdType'),
        field: 'adType',
        thClass: 'uk-width-medium uk-text-truncate',
        tdClass: 'uk-width-medium uk-text-middle',
        sortable: true
      },
      {
        label: this.$t('Pages.Advertising.Labels.Resolution'),
        field: 'resolution',
        thClass: 'uk-width-small',
        tdClass: 'uk-width-small uk-text-middle',
        sortable: false
      },
      {
        label: this.$t('Actions.Created'),
        field: 'created',
        thClass: 'uk-width-medium',
        tdClass: 'uk-width-medium uk-text-middle',
        sortable: true
      },
      {
        label: this.$t('Actions.Updated'),
        field: 'lastUpdated',
        thClass: 'uk-width-medium',
        tdClass: 'uk-width-medium uk-text-middle',
        sortable: true
      },
      {
        label: this.$t('Pages.Advertising.LinkedContents'),
        field: 'linkedContent',
        thClass: 'uk-width-medium uk-text-truncate',
        tdClass: 'uk-width-medium uk-text-middle',
        sortable: false
      },
      {
        label: this.$t('Actions.Actions'),
        field: 'actions',
        thClass: 'uk-width-small uk-text-middle',
        tdClass: 'uk-width-small uk-text-middle',
        sortable: false
      }]
    },
    filteredAds() {
      const ads = this.ads.filter(ad => {
        if (!this.searchKey || ad.title?.toLowerCase().includes(this.searchKey.toLowerCase()) || ad.tags?.includes(this.searchKey.toLowerCase())) {
          if (this.filterType === 'all') {
            return true
          } else if ( this.filterType === 'video' ) {
            return ad.__typename === 'VideoAd'
          } else if ( this.filterType === 'medium_banner' ) {
            return ad.__typename === 'BannerAd' && ad.size === 'MEDIUM_BANNER'
          } else if ( this.filterType === 'small_banner' ) {
            return ad.__typename === 'BannerAd' && ad.size === 'SMALL_BANNER'
          } else if ( this.filterType === 'promoted' ) {
            return ad.__typename === 'PromotedAd'
          }
        } else {
          return false
        }
      })
      return ads;
    },
    formattedFilteredAds () {
      return this.filteredAds.map(ad => {
        return {
          name: ad.title,
          icon: ad?.banner?.url || 'NA',
          adType: this.$t('Pages.Advertising.AdTypes')[ad.__typename === 'BannerAd' ? ad.size : ad.__typename],
          resolution: this.getAdResolution( ad ),
          created: ad.createdAt,
          lastUpdated: ad.lastUpdated,
          linkedContent: ad.linkedContent,
          actions: {},
          id: ad.id,
          size: ad.size,
          title: ad.title,
          __typename: ad.__typename
        }
      })
    }
  },
  beforeDestroy () {
    const modalElement = document.getElementById('prepare-ad-modal')
    const infoModalElement = document.getElementById('info-ad-modal')
    const linkedContentsModal = document.getElementById('linked-content-modal')
    if (modalElement) {
      window.UIkit.modal('#prepare-ad-modal').$destroy(true);
    }
    if (infoModalElement) {
      window.UIkit.modal('#info-ad-modal').$destroy(true)
    }
    if (linkedContentsModal) {
      window.UIkit.modal('#linked-content-modal').$destroy(true)
    }
  },
  methods: {
    showLinkedContents (linkedContents, title) {
      this.linkedContentsToShow = linkedContents
      this.titleToShow = title
      this.$nextTick(() => {
        window.UIkit.modal(`#linked-content-modal`).show();
      })
    },
    createNewAd () {
      this.isUpdate = false
      this.adType = 'video'
      this.adId = ''
      setTimeout(() => {
        window.UIkit.modal(`#prepare-ad-modal`).show();
      }, 0)
    },
    editAd (ad) {
      this.isUpdate = true
      this.adId = ad.id
      let type = 'video'
      if (ad.__typename === 'VideoAd') type = 'video'
      else if (ad.__typename === 'BannerAd') type = ad.size === 'MEDIUM_BANNER' ? 'medium-banner' : 'small-banner'
      else type = 'promoted'
      this.adType = type
      setTimeout(() => {
        window.UIkit.modal('#prepare-ad-modal').show()
      }, 0)
    },
    async fetchAds( isNextPage, paginatedResponse ) {
      this.fetching = true;
      const response = await this.$store.dispatch('fetchAds', {
        orgId: this.orgId,
        first: 25,
        after: isNextPage && paginatedResponse ? ( paginatedResponse.pageInfo.hasNextPage ? paginatedResponse.pageInfo?.endCursor : '' ) : '',
        isNextPage: isNextPage
      });   
      this.fetching = false;
      this.firstFetch = false
      if ( response?.pageInfo?.hasNextPage) {
        this.fetchAds( true, response );
      }
      if ( response.errors ) {
        Notification.showNotification(this.$('Pages.AppEdit.Error.DeleteAssetError'), response.errors[0].message, 'error')
      }
    },
    getAdResolution( ad ) {
      if ( ad ) {
        let resolution = "";
        if ( ad.video && ad.video.resolution ) {
          resolution = `${ad.video.resolution.width}x${ad.video.resolution.height}`;
          if ( resolution === '0x0' )  resolution = '';
        }
        return resolution;
      }
      return "";
    },
    deleteAd( ad ) {
      if ( ad.linkedContent && ad.linkedContent.length > 0 ) {
        this.cannotDeleteAdMessage = this.$t( 'DeleteModal.AdCannotBeDeleted', { name: ad.title } );
        window.UIkit.modal('#info-ad-modal').show();
      } else {
        window.UIkit.modal.confirm( this.$t( 'DeleteModal.ConfirmDeleteAd', { name: ad.title?.length > 25 ? ad.title.substring(0,25) + '...' : ad.title }), { labels: {
                cancel: this.$t('Actions.Cancel'), ok: this.$t('Actions.Delete')
            }} ).then(async () => {
          this.deletingList.push( ad );
          const response = await this.$store.dispatch('deleteAd', { adId: ad.id });
          if (response.message) {
            Notification.showNotification(this.$t('Pages.Advertising.Notifications.AdNotDeleted'), response.message, 'warn')
            this.deletingList.pop()
          } else {
            await this.fetchAds( false );
            Notification.showNotification( this.$t( 'Generic.Messages.Successful' ), this.$t( 'DeleteModal.AdDeletedSuccessfully' ) );
          }
        },() => {
      });
      }
    },
    changeFilter( type ) {
      this.filterType = type;
      this.filterClass = {
        all: '',
        video: '',
        mediumb: '',
        smallb: '',
        promoted: ''
      }
      this.filterClass[ type ] = 'uk-active uk-text-bold';
    },
    updateSearchKeyInURL () {
      const url = new URL(window.location);
      url.searchParams.set('search', this.searchKey);
      window.history.pushState({}, '', url);
    },
    clearSearch () {
      this.searchKey = '';
      const url = new URL(window.location);
      url.searchParams.delete('search');
      window.history.pushState({}, '', url);
    }
  },
  async mounted() {
    document.documentElement.scrollTop = 0
    if ( await isAdBlocker() ) {
      this.isAdBlockerEnabled = true;
      Notification.showNotification( this.$t( 'Pages.Advertising.Notifications.AdBlockerNotice' ), this.$t( 'Pages.Advertising.Notifications.AdBlockerMessage' ), 'error' );
    } else {
      this.firstFetch = true
      await this.fetchAds( false );
      if( this.$route.query.ad){
        this.editAd(this.ads.filter( a => a.id === this.$route.query.ad )?.[0])
      }
    }
  }
}
</script>

<style lang="scss">
  .new-btn {
    padding: 0 20px;
  }
table.vgt-table, .vgt-input {
  background: none;
  border: 1px solid var(--border-color);
}
.vgt-inner-wrap {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.vgt-global-search {
  background: none;
  border: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.vgt-global-search label {
  position: absolute;
  left: 10px;
}
.vgt-global-search__input .input__icon .magnifying-glass  {
  margin-left: 0px;
}
.vgt-global-search .vgt-input {
  width: 40%;
  margin-left: -40px;
  height: 40px;
  padding-left: 40px;
  color: var(--text-color);
}
.vgt-global-search .vgt-input::placeholder {
  color: var(--text-color);
}
table.vgt-table {
  font-size: 12px;
  border: 0px;
}
table.vgt-table td {
  border: 0px;
  color: var(--text-color);
}
.vgt-table th.sortable button {
  width: auto;
  position: relative;
  margin-left: 5px;
  top: -.25rem;
}
.vgt-table thead th {
  background: none;
  color: var(--white-color);
  border: 0px;
  width: initial;
}
.vgt-table th.line-numbers {
  background: none;
  border-right: 1px solid var(--border-color);
}
.vgt-table th.sortable button:after {
  border-bottom: 5px solid var(--white-color);
}
.vgt-table th.sortable button:before {
  border-top: 5px solid var(--white-color);
}
.vgt-table .custom-center span {
  padding-left: 10px;
}
.vgt-wrap__footer {
  background: none;
  border: 0px;
}
.vgt-wrap__footer .footer__navigation__page-info__current-entry {
  background: none;
  border: 1px solid var(--border-color);
  color: #909399;
  padding: 2px;
}
.vgt-wrap__footer .footer__row-count__label {
  font-size: 13px;
}
  
</style>