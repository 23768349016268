<template>
  <div id="linked-content-modal" uk-modal="bg-close: false; esc-close: false;" class="uk-modal">
    <div class="uk-modal-dialog uk-width-2-5">
      <button class="uk-modal-close-default" type="button" uk-close></button>
      <div class="uk-modal-header">
        <h2 class="uk-modal-title uk-text-left">
          {{$t('Pages.Advertising.LinkedContents')}}
        </h2>
      </div>
      <div class="uk-modal-body">
        <div class="uk-margin-small uk-text-left">
          <ul>
            <li v-for="linkedContent of linkedContents" :key=linkedContent.id>
              <div class="uk-text-truncate uk-width-1-1">
                <router-link :to="{ name: 'ContentDetails', params: { id: orgId, propertyId: linkedContent.collection.property.id, contentId: linkedContent.id}}" >
                  {{`${linkedContent.collection.property.i18nName.localized.value} / ${linkedContent.collection.i18nName.localized.value} / ${title}`}}
                </router-link>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: 'LinkedContentsModal',
  props: {
    linkedContents: {
      type: Array
    },
    title: {
      type: String
    },
    orgId: {
      type: String
    }
  },
  data () {
    return {}
  }
}
</script>
