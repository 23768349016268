
<template>
  <div id="prepare-ad-modal" class="uk-modal-container" uk-modal="bg-close: false; esc-close: false; stack: true">
    <div class="uk-modal-dialog " uk-overflow-auto>
      <button class="uk-modal-close-default" type="button" uk-close></button>

      <div class="uk-modal-header uk-text-left">
        <span class="uk-text-left uk-text-large uk-width-auto">{{modalTitle}}</span>
        <span class="uk-text-danger uk-margin-small-left" v-if="formChanged && !isLoading">{{$t('Generic.Labels.Unsaved')}}</span> 
      </div>
      <div class="uk-modal-body">
          <div v-if="!isLoading" class="uk-width-expand@m">

            <div class="uk-container-expand">

              <div class="uk-child-width-expand@s uk-grid-divider" uk-grid>

                <div class="uk-width-1-2@m">
                  <form class="uk-form-stacked">

                    <div class="uk-margin-small uk-margin-bottom"  v-if="['video', 'medium-banner', 'small-banner', 'promoted'].includes(type)">
                      <label class="uk-form-label uk-text-left" for="form-horizontal-text">
                        {{$t("Pages.Advertising.Labels.AdType")}}
                        <span class="uk-text-danger">*</span>
                      </label>
                      <div class="uk-form-controls">
                        
                        <select class="uk-select uk-width-1-1" id="app-platform" v-model="selectedType" :disabled="this.isUpdate">
                          <option
                            v-for="(adType, di) in ['video', 'small-banner', 'medium-banner', 'promoted']"
                            :key="di"
                            :value="adType"
                          >
                            {{ $t('Pages.Advertising.AdTypes')[adType] }}
                          </option>
                        </select>
                      </div>
                    </div>

                    <div class="uk-margin-small uk-margin-bottom"  v-if="['video', 'medium-banner', 'small-banner', 'promoted'].includes(type)">
                      <label class="uk-form-label uk-text-left" for="form-horizontal-text">
                        {{$t("Pages.Advertising.Labels.Title")}}
                        <span class="uk-text-danger">*</span>
                      </label>
                      <div class="uk-form-controls">
                        <div class="uk-inline uk-width-1-1">
                          <input type="text" class="uk-input" :class="{ 'invalid-border': isTitleInvalid }" :placeholder="$t('Pages.Advertising.Placeholders.Title')" v-model.trim="form.title" @keyup="validateInput('title')">
                          <span class="uk-form-icon uk-form-icon-flip invalid-icon" v-if="isTitleInvalid" uk-icon="icon: warning" ></span>
                          <span class="uk-form-icon uk-form-icon-flip uk-width-auto uk-margin-medium-right inline-error-bg" v-if="isTitleInvalid" >{{isTitleInvalid}}</span>
                        </div>
                      </div>
                    </div>

                    <div class="uk-margin-small uk-margin-bottom" v-if="['video', 'medium-banner', 'small-banner', 'promoted'].includes(type)">
                      <label class="uk-form-label uk-text-left" for="form-horizontal-text">
                        {{$t("Pages.Advertising.Labels.Tags")}}
                      </label>
                      <div class="uk-form-controls">
                        <div class="uk-inline uk-width-1-1">
                          <input 
                            class="uk-input"
                            :class="{ 'invalid-border': errors.tag }"
                            type="text"
                            v-model.trim="form.tag"
                            autocomplete="off"
                            :placeholder="$t('Pages.Content.Placeholders.Tags')"
                            @keyup.enter="addTag()"
                            @focus="errors.tag = ''"
                            />
                            <span class="uk-form-icon uk-form-icon-flip invalid-icon" v-if="errors.tag" uk-icon="icon: warning" ></span>
                            <span class="uk-form-icon uk-form-icon-flip uk-width-auto uk-margin-medium-right inline-error-bg" v-if="errors.tag" >{{errors.tag}}</span>

                        </div>

                          <br>
                          <div class="">
                            <div v-for="tag in form.tags" :key="tag" class="tag-item uk-margin-small-top uk-margin-small-right" style="max-width:95%">
                              <div class="label uk-margin-small-left uk-margin-small-right uk-text-truncate uk-width-auto" @click="$emit('click')">
                                  <span :key="tag" :uk-tooltip="tag"> {{ tag }} </span>
                              </div>
                              <span class="close uk-margin-auto-vertical uk-margin-small-right" style="cursor: pointer;" uk-icon="icon: close;ratio: 0.8" @click="deleteTag(tag)"></span>
                            </div>

                          </div>
                      </div>
                    </div>

                    <div class="uk-margin-small uk-margin-bottom"  v-if="['promoted'].includes(type)">
                      <label class="uk-form-label uk-text-left" for="form-horizontal-text">
                        {{$t("Pages.Advertising.Labels.Description")}}
                      </label>
                      <div class="uk-form-controls">
                        <div class="uk-inline uk-width-1-1">
                          <textarea class="uk-textarea" :placeholder="$t('Pages.Advertising.Placeholders.Description')" v-model.trim="form.description"></textarea>
                        </div>
                      </div>
                    </div>
                    
                    <div class="uk-margin-small" v-if="['video', 'promoted'].includes(type)">
                      <label class="uk-form-label uk-text-left" for="form-horizontal-text">
                        {{$t("Pages.Advertising.Labels.Projection")}}
                      </label>
                      <div class="uk-form-controls">
                        <div class="uk-inline uk-width-1-1">
                          <div class="uk-child-width-auto uk-text-left">
                              <label><input class="uk-radio" type="radio" name="projection" checked value="EQUIRECTANGULAR" v-model="form.projection">  {{$t('enums.ProjectionType.EQUIRECTANGULAR')}}</label>
                              <br>
                              <label><input class="uk-radio" type="radio" name="projection" value="FLAT" v-model="form.projection">  {{$t('enums.ProjectionType.FLAT')}}</label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="uk-margin-small" v-if="['video', 'promoted'].includes(type)">
                      <label class="uk-form-label uk-text-left" for="form-horizontal-text">
                        {{$t("Pages.Advertising.Labels.VideoSource")}}
                        <span class="uk-text-danger" v-if="['video'].includes(type)">*</span>
                        <span class="uk-align-right uk-margin-remove invalid-icon" v-if="(type === 'video') && isVideoMissing" uk-icon="icon: warning" ></span>
                        <span class="uk-align-right uk-margin-small-right uk-margin-remove-bottom uk-text-danger " v-if="(type === 'video') && isVideoMissing">{{errors.video}}</span>
                      </label>
                      <div class="uk-form-controls uk-text-left">                        
                        <div class="uk-width-1-1 image-placeholder-min-height uk-text-left">
                          <div class="uk-width-1-1 uk-text-center">
                            <button class="uk-button uk-button-primary custom-button-padding uk-align-left" style="margin-top:60px" @click="openMediaPicker('video', 'VIDEO')" type="button">
                              <span class="uk-margin-small-right uk-padding-remove" uk-icon="plus-circle"></span> {{$t('Pages.Advertising.Labels.AddVideo')}}
                            </button>
                            <!-- <video id="ad-video-src" :src="getVideoUrl" uk-video="autoplay: false" controls=true style="height:150px;"></video> -->
                            <VideoOrFallback :src="getVideoUrl" height="150px" />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="uk-margin-small uk-margin-medium-bottom" v-if="['medium-banner', 'small-banner'].includes(type)">
                      <label class="uk-form-label uk-text-left" for="form-horizontal-text">
                        {{$t("Pages.Advertising.Labels.DestinationURL")}}
                        <span class="uk-text-danger">*</span>
                      </label>
                      <div class="uk-form-controls">
                        <div class="uk-inline uk-width-1-1">
                          <input type="text" class="uk-input" :class="{ 'invalid-border': !focused.destinationURL && errors.destinationURL }" @focus="focused.destinationURL = true" @blur="focused.destinationURL = false"
                          :placeholder="$t('Pages.Advertising.Placeholders.Title')" v-model.trim="form.destinationURL" @keyup="validateInput('destinationURL')">
                          <span class="uk-form-icon uk-form-icon-flip invalid-icon" v-if="!focused.destinationURL && errors.destinationURL" uk-icon="icon: warning"></span>
                          <span class="uk-form-icon uk-form-icon-flip uk-width-auto uk-margin-medium-right inline-error-bg" v-if="!focused.destinationURL && errors.destinationURL" >{{errors.destinationURL}}</span>
                        </div>
                      </div>
                    </div>

                  </form>
                </div>

                <div class="uk-width-1-2@m">
                  <form class="uk-form-stacked" @click.prevent="">

                    <div class="uk-margin-small uk-margin-bottom"  v-if="['promoted'].includes(type)">
                      <label class="uk-form-label uk-text-left" for="form-horizontal-text">
                        {{$t("Pages.Advertising.Labels.Attribution")}}
                      </label>
                      <div class="uk-form-controls">
                        <div class="uk-inline uk-width-1-1">
                          <input type="text" class="uk-input" :placeholder="$t('Pages.Advertising.Placeholders.Attribution')" v-model.trim="form.attribution">
                        </div>
                      </div>
                    </div>
                    
                    <div class="uk-margin-small " v-if="['video', 'promoted'].includes(type)">
                      <label class="uk-form-label uk-text-left " for="form-horizontal-text">
                        {{$t("Pages.Advertising.Labels.Keyart")}}
                        <span class="uk-text-danger" v-if="type === 'promoted'">*</span>
                        <span class="uk-align-right uk-margin-remove-bottom uk-margin-small-left invalid-icon uk-margin-small-right uk-text-danger " v-if="(type === 'promoted') && isKeyartMissing" uk-icon="icon: warning" ></span>
                        <span class="uk-align-right uk-margin-remove-bottom uk-text-danger" v-if="(type === 'promoted') && isKeyartMissing">{{isKeyartMissing}}</span>
                      </label>
                      <div class="uk-form-controls">
                        <div class="uk-width-1-1 image-placeholder-min-height uk-text-left">
                          <div class="uk-width-1-1 uk-text-center">
                            <button class="uk-button uk-button-primary custom-button-padding uk-align-left uk-text-truncate" style="margin-top:60px; max-width: 40%" @click.prevent="openMediaPicker('keyart', 'IMAGE')" type="button">
                              <span class="uk-margin-small-right uk-padding-remove" uk-icon="plus-circle"></span> {{$t('Pages.Advertising.Labels.AddKeyart')}}
                            </button>
                            <ImgOrFallback :src="getKeyartUrl"  height="150px" width="260px" imageClass='uk-border-rounded' />
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div class="uk-margin-small" v-if="['medium-banner', 'small-banner'].includes(type)">
                      <label class="uk-form-label uk-text-left" for="form-horizontal-text">
                        {{$t("Pages.Advertising.Labels.Banner")}}
                        <span class="uk-text-danger">*</span>
                        <span class="uk-align-right uk-margin-remove-bottom uk-margin-small-left invalid-icon uk-margin-small-right uk-text-danger " v-if="isBannerMissing" uk-icon="icon: warning" ></span>
                        <span class="uk-align-right uk-margin-remove-bottom uk-text-danger" v-if="isBannerMissing">{{isBannerMissing}}</span>
                      </label>
                      <div class="uk-form-controls">
                        <div class="uk-width-1-1 image-placeholder-min-height uk-text-left">
                          <div class="uk-width-1-1 uk-text-center">
                            <button class="uk-button uk-button-primary custom-button-padding uk-align-left" style="margin-top:60px" @click.prevent="openMediaPicker('banner', 'IMAGE')" type="button">
                              <span class="uk-margin-small-right uk-padding-remove" uk-icon="plus-circle"></span> {{$t('Pages.Advertising.Labels.AddBanner')}}
                            </button>
                            <ImgOrFallback :src="getBannerUrl"  height="150px" width="260px" imageClass='uk-border-rounded'/>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div class="uk-margin-small uk-margin-medium-top" v-if="['video', 'medium-banner', 'promoted'].includes(type)">
                      <label class="uk-form-label uk-text-left" for="form-horizontal-text">
                        {{$t("Pages.Advertising.Labels.Logo")}}
                        <span class="uk-text-danger" v-if="['medium-banner'].includes(type)">*</span>
                        <span class="uk-align-right uk-margin-remove-bottom uk-margin-small-left invalid-icon uk-margin-small-right uk-text-danger" v-if="isLogoMissing" uk-icon="icon: warning" ></span>
                        <span class="uk-align-right uk-margin-remove-bottom uk-text-danger" v-if="isLogoMissing">{{isLogoMissing}}</span>
                      </label>
                      <div class="uk-form-controls image-placeholder-min-height uk-text-left">
                        <div class="uk-width-1-1 uk-text-center">
                          <button class="uk-button uk-button-primary custom-button-padding uk-align-left" style="margin-top:60px" @click.prevent="openMediaPicker('logo', 'IMAGE')" type="button">
                            <span class="uk-margin-small-right uk-padding-remove" uk-icon="plus-circle"></span> {{$t('Pages.Advertising.Labels.AddLogo')}}
                          </button>  
                          <ImgOrFallback  :src="getLogoUrl"  height="150px" width="260px" imageClass='uk-border-rounded'/>
                        </div>
                      </div>
                    </div>
                    
                  </form>
                </div>

                <div class="uk-container uk-width-1-1@m uk-padding-remove-right" v-if="['promoted'].includes(type)">
                  <form class="uk-form-horizontal" @click.prevent="">
                    <hr>
                    <div class="uk-container-xlarge uk-align-center">

                      <div>
                        <div class="" uk-grid>
                          <div class="uk-width-2-5@s">
                            <label class="uk-form-label uk-text-left" style="width:100%"> 
                              DeepLink URL
                              <!-- <div class="uk-inline uk-align-right uk-margin-remove">
                                <span class="uk-align-right uk-margin-remove-bottom uk-margin-small-left invalid-icon uk-margin-small-right" v-if="errors.deepLinks" uk-icon="icon: warning" ></span>
                                <span class="uk-align-right uk-margin-remove-bottom" v-if="errors.deepLinks">{{errors.deepLinks}}</span>
                              </div> -->
                            </label>
                          </div>
                          <div class="uk-width-1-5@s">
                            <label class="uk-form-label uk-heading-line uk-text-center "> {{$t('Pages.Application.Platform')}} </label>
                          </div>
                          <div class="uk-width-2-5@s">
                            <label class="uk-form-label uk-text-left uk-width-2-5@s"> Bundle ID </label>
                          </div>
                        </div>
                      </div>
                      <br>

                      <div v-for="device in devices" :key="device">
                        
                        <div class="uk-margin-small uk-margin-bottom" :key="device + 'level1div'">
                          
                          <div class="" uk-grid>
                            <div class="uk-inline uk-width-2-5@s">
                              <input 
                                :id="'deeplink-url-' + device"
                                :key="device + 'input'"
                                type="url"
                                class="uk-input" 
                                :placeholder="$t('Pages.Advertising.Placeholders.DeepLink').replace('{{device}}', $t('enums.DeviceType')[device])" 
                                v-model.trim="form.deepLinks[device].uri"
                                @blur="validateDeepLinkOnBlur(device)"
                                @focus="toggleDeepLinkErrorMsg(device, false)"
                              >
                              <span :id="'validation-icon-' + device" class="uk-form-icon uk-form-icon-flip invalid-icon" uk-icon="icon: warning"></span>
                              <span :id="'validation-msg-' + device" class="inline-error-bg uk-form-icon uk-form-icon-flip uk-width-auto uk-margin-medium-right uk-text-danger" @blur="toggleDeepLinkErrorMsg(device, false)" >  {{errors.deepLinks[device] && errors.deepLinks[device]}}</span>
                            </div>
                            <div class="uk-width-1-5@s">
                              <label class="uk-text-center uk-heading-line uk-form-label uk-margin-small-top" for="form-horizontal-text">
                                <span>
                                  {{device === 'HTML5' ? 'WEB' : $t("enums.DeviceType")[device]}}
                                </span>
                              </label>
                            </div>
                            <div class="uk-width-2-5@s">
                              <input 
                                type="text" 
                                class="uk-input" 
                                :placeholder="['HTML5'].includes(device) ? 'N.A' : $t('Pages.Advertising.Placeholders.BundleId').replace('{{device}}', $t('enums.DeviceType')[device])" 
                                v-model.trim="form.deepLinks[device].bundleId"
                                :disabled="['HTML5'].includes(device)">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div v-if="isLoading">
            <span uk-spinner></span>
          </div>
      </div>
      
      <div class="uk-modal-footer uk-text-right">
        <span class="uk-text-danger" v-if="!isFormValid">{{$t('Generic.Errors.PageHasErrors')}}
          <span class="uk-margin-small-right uk-padding-remove" uk-icon="icon: warning"></span>
        </span>
        <div class="uk-margin-small-top">
          <button class="uk-button uk-button-default uk-modal-close uk-margin-right" @click="clearForm()">{{ $t('Actions.Cancel') }}</button>
          <button v-if="!isSaving" class="uk-button uk-button-primary" :disabled="false" @click.prevent="saveAd()">{{ $t('Actions.Save') }}</button>
          <button v-else class="uk-button uk-button-primary" type="button" disabled><div uk-spinner="ratio: 0.5"></div></button>
        </div>
      </div>
      
      <div v-if="showPicker" id="media-picker-modal" uk-modal>
        <div class="uk-modal-dialog uk-modal-body uk-width-3-4 media-picker-modal">
          <ModalMediaPicker  :media-class-filter="mediaTypeToBePicked" @cancel="closeModal()" @select="selectedMedia( $event )" />
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import ImgOrFallback from '@/components/images/ImageOrFallback';
import VideoOrFallback from '@/components/VideoOrFallback';
import ModalMediaPicker from '@/components/modals/ModalMediaPicker.vue';
import Notification from '@/components/util/Notification';
import { mapState } from 'vuex';

export default {
  name: 'PrepareAdModal',
  components: {
    ImgOrFallback,
    ModalMediaPicker,
    VideoOrFallback
  },
  props: {
    adId: {
      type: String
    },
    isUpdate: {
      type: Boolean
    },
    adType: {
      type: String
    }
  },
  data () {
    return {
      formChanged: false,
      detectChange: false,
      formSignature: '',
      selectedType: 'video',
      isLoading: false,
      orgId: this.$route.params.id,
      pickingMediaForField: 'logo',
      mediaTypeToBePicked: 'video',
      showPicker: false,
      dontClearForm: false,
      isSaving: false,
      devices: ["IOS", "ANDROID", "GEARVR", "OCULUS_RIFT", "OCULUS_GO", "OCULUS_QUEST", "HTC_VIVE", "DAYDREAM", "PICO", "HUAWEI", "HTML5"],
      focused: {
        destinationURL: false
      },
      form: {
        id: '',
        title: '',
        tag: '',
        tags: [],
        projection: '',
        keyart: '',
        keyartPreview: '',
        logo: '',
        logoPreview: '',
        video: '',
        videoPreview: '',
        banner: '',
        bannerPreview: '',
        attribution: '',
        destinationURL: '',
        deepLinks: {
          IOS: {
            uri: '',
            bundleId: ''
          },
          ANDROID: {
            uri: '',
            bundleId: ''
          },
          GEARVR: {
            uri: '',
            bundleId: ''
          },
          OCULUS_RIFT: {
            uri: '',
            bundleId: ''
          },
          OCULUS_GO: {
            uri: '',
            bundleId: ''
          },
          OCULUS_QUEST: {
            uri: '',
            bundleId: ''
          },
          HTC_VIVE: {
            uri: '',
            bundleId: ''
          },
          DAYDREAM: {
            uri: '',
            bundleId: ''
          },
          PICO: {
            uri: '',
            bundleId: ''
          },
          HUAWEI: {
            uri: '',
            bundleId: ''
          },
          HTML5: {
            uri: '',
            bundleId: ''
          }
        }
      },
      video: {
        title: true,
        tags: false,
        projection: true,
        keyart: false,
        video: true,
        logo: false
      },
      "small-banner": {
        title: true,
        tags: false,
        destinationURL: true,
        banner: true
      },
      "medium-banner": {
        title: true,
        tags: false,
        destinationURL: true,
        banner: true,
        logo: true
      },
      promoted: {
        title: true,
        tags: false,
        description: false,
        attribution: false,
        keyart: true,
        logo: false,
        video: false,
        projection: false,
        deepLinks: false,
      },
      errors: {
        title: '',
        tag: '',
        tags: '',
        projection: '',
        keyart: '',
        keyartPreview: '',
        logo: '',
        logoPreview: '',
        video: '',
        videoPreview: '',
        banner: '',
        bannerPreview: '',
        attribution: '',
        destinationURL: '',
        deepLinks: {          
          IOS: '',
          ANDROID: '',
          GEARVR: '',
          OCULUS_RIFT: '',
          OCULUS_GO: '',
          OCULUS_QUEST: '',
          HTC_VIVE: '',
          DAYDREAM: '',
          PICO: '',
          HUAWEI: '',
          HTML5: ''
        }
      }
    }
  },
  computed: {
    type () {
      if (!this.isUpdate) return this.selectedType
      else return this.adType
    },
    modalTitle () {
      return this.isUpdate ? this.$t('Pages.Advertising.Labels.EditAd').replace('{{type}}', this.$t('Pages.Advertising.AdTypes')[this.type]) : this.$t('Pages.Advertising.Labels.CreateAd').replace('{{type}}', this.$t('Pages.Advertising.AdTypes')[this.type])
    },
    ...mapState({
      ads: state => (state.venom.org.ads) ? state.venom.org.ads.nodes : [],
      pageInfo: state => (state.venom.org.ads) ? state.venom.org.ads.pageInfo : {}
    }),
    isTitleInvalid() {
      this.validateInput('title')
      return this.errors.title
    },
    isVideoMissing () {
      this.validateInput('video')
      return this.errors.video
    },
    isLogoMissing () {
      this.validateInput('logo')
      return this.errors.logo
    },
    isBannerMissing () {
      this.validateInput('banner')
      return this.errors.banner
    },
    isKeyartMissing () {
      this.validateInput('keyart')
      return this.errors.keyart
    },
    getVideoUrl () {
      return this.form.video?.file?.url || this.form.videoPreview
    },
    getKeyartUrl () {
      return this.form.keyart?.file?.url || this.form.keyartPreview
    }, 
    getLogoUrl () {
      return this.form.logo?.file?.url || this.form.logoPreview
    },
    getBannerUrl () {
      return this.form.banner?.file?.url || this.form.bannerPreview
    },
    isFormValid () {
      let isValid = true
      const fieldList = this[this.type]
      for (const key in fieldList) {
        if (key === 'deepLinks') {
          for (let deepLink in this.errors.deepLinks) {
            if (this.errors.deepLinks[deepLink]) {
              isValid = false
            }
          }
        } else if (fieldList[key]) {
          if (this.errors[key]) {
            isValid = false
          }
        }
      }
      return isValid
    }
  },
  watch: {
    type () {
      if (this.type === 'promoted') {
        setTimeout(() => {
          for (const device of this.devices) {
            this.toggleDeepLinkErrors(device, false)
          }
        }, 0)
      }
    },
    form: {
      handler () {
        if (this.isUpdate && this.detectChange && this.formSignature) {
          process.nextTick(() => {
            const newSignature = this.getSignature()
            if (newSignature !== this.formSignature) {
              this.formChanged = true
            } else {
              this.formChanged = false
            }
          })
        }
      },
      deep: true,
      immediate: true
    }
  },
  mounted () {
    window.UIkit.util.on(document, 'beforeshow', '#prepare-ad-modal', async () => {
      if (!this.dontClearForm) {
        this.clearForm()
      }
      if (this.isUpdate && this.adId && !this.dontClearForm) {
        this.dontClearForm = false
        this.selectedType = this.type
        this.isLoading = true
        const adData = await this.$store.dispatch('getAd', { adId: this.adId, orgId: this.$route.params.id })
        this.setAdDataInForm(adData)
        this.isLoading = false
        process.nextTick(() => {
          this.formSignature = this.getSignature()
          this.formChanged = false
          this.detectChange = true
        })
      } 
      if (!this.isUpdate && !this.dontClearForm) {
        this.$nextTick(() => {
          this.formSignature = this.getSignature()
          this.formChanged = false
          this.detectChange = true
        })
      }
      if (this.type === 'promoted') {
        this.$nextTick(() => {
          for (const device of this.devices) {
            this.toggleDeepLinkErrors(device, false)
          }
        })
      }
    })
  },
  methods: {
    getSignature () {
      const signObj = Object.assign({}, this.form)
      delete signObj.logo
      return JSON.stringify(signObj)
    },
    toggleDeepLinkErrors (device, status) {
      const icon = document.getElementById('validation-icon-' + device)
      const input = document.getElementById('deeplink-url-' + device)
      this.toggleDeepLinkErrorMsg(device, status)
      if (input) {
        if (status) {
          input.classList.add('invalid-border')
        } else {
          input.classList.remove('invalid-border')
        }
      }
      if (!icon || !icon.style) return
      if (status) {
        icon.style.display = 'flex'
      } else {
        icon.style.display = 'none'
      }
    },
    toggleDeepLinkErrorMsg (device, status) {
      const validationMessage = document.getElementById('validation-msg-' + device)
      const icon = document.getElementById('validation-icon-' + device)
      const input = document.getElementById('deeplink-url-' + device)
      if (!validationMessage || !validationMessage.style) return
      if (status) {
        validationMessage.style.display = 'flex'
      } else {
        validationMessage.style.display = 'none'
      }
      if (!icon || !icon.style) return
      if (status) {
        icon.style.display = 'flex'
      } else {
        icon.style.display = 'none'
      }
      if (input) {
        if (status) {
          input.classList.add('invalid-border')
        } else {
          input.classList.remove('invalid-border')
        }
      }
    },
    setAdDataInForm (adData) {
      const form = this.form
      form.id = adData.id
      form.title = adData.title
      form.tags = adData.tags
      if (this.type === 'video') {
        form.keyart = form.keyartPreview = adData.banner?.url // Did these to evade validation on update
        form.logo = form.logoPreview = adData.logo?.url
        form.video = form.videoPreview = adData.video?.file?.url
        form.projection = adData.projection || "EQUIRECTANGULAR"
      } else if (this.type === 'medium-banner') {
        form.banner = form.bannerPreview = adData.banner?.url
        form.logo = form.logoPreview = adData.logo?.url
        form.destinationURL = adData.destinationUrl
      } else if (this.type === 'small-banner') {
        form.banner = form.bannerPreview = adData.banner?.url
        form.destinationURL = adData.destinationUrl
      } else {
        form.projection = adData?.projection
        form.video = form.videoPreview = adData?.video?.file?.url
        form.description = adData.description
        form.attribution = adData.attribution
        form.keyart = form.keyartPreview = adData.banner?.url
        form.logo = form.logoPreview = adData.logo?.url
        if (adData.deepLinks?.length) {
          for(const deepLink of adData.deepLinks) {
            if (form.deepLinks[deepLink.platform] && typeof form.deepLinks[deepLink.platform] === "object") {
              form.deepLinks[deepLink.platform].uri = deepLink.uri
              form.deepLinks[deepLink.platform].bundleId = deepLink.bundleId
            } else {
              form.deepLinks[deepLink.platform] = {
                ...deepLink
              }
            }
          }
        }
      }
    },
    validateDeepLinkOnBlur (device) {
      if (typeof this.errors.deepLinks !== 'object') {
        this.errors.deepLinks = {}
      }
      if (!this.form.deepLinks[device].uri || /(http(s)?:\/\/.){1}(www\.){0,1}[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/i.test(this.form.deepLinks[device].uri)) {
        this.errors.deepLinks[device] = false
        this.toggleDeepLinkErrors(device, false)
      } else {
        this.errors.deepLinks[device] = this.$t('Pages.Advertising.Labels.FixDeepLinks')
        this.toggleDeepLinkErrors(device, true)
      }
    },
    validateInput( field ) {
        if (!this.form[field]) {
          if (!this.errors[field]) {
            this.errors[field] = false
          }
          return false
        }
        if (this.form[field].length > 0 && this.form[field].length < 2) {
          this.errors[field] = this.$t('Generic.Errors.Min2Chars')
        } else {
          if ((typeof this.form[field] === 'object' && Object.keys(this.form[field]).length) || this.form[field].length) {
            this.errors[field] = false
          }
        }
      if ( (field === 'destinationURL') && this.form[field].length) {
        if (this.form.destinationURL.split(" ").length > 1) {
          this.form.destinationURL = this.form.destinationURL.split(" ")[0]
        }
        this.errors.destinationURL = /(http(s)?:\/\/.){1}(www\.){0,1}[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/i.test( this.form.destinationURL ) ? false : this.$t('Pages.Advertising.Labels.ValidHttps')
      }
    },
    selectedMedia (media) {
      this.form[this.pickingMediaForField] = media.__typename === 'MediaBundle' ? media.masterVideo : media;
      this.form[`${this.pickingMediaForField}Preview`] = false
      this.closeModal();
    },
    async fetchAds(isNextPage) {
      await this.$store.dispatch('fetchAds', {
        orgId: this.$route.params.id,
        first: 25,
        after: isNextPage ? ( this.pageInfo.hasNextPage ? this.pageInfo?.endCursor : '' ) : '',
        isNextPage: isNextPage
      });   
      this.fetching = false;
      if ( this.pageInfo?.hasNextPage) {
        this.fetchAds( true );
      }
    },
    openMediaPicker (field, type) {
      this.pickingMediaForField = field
      this.mediaTypeToBePicked = type
      this.showPicker = true;
      this.$nextTick( () => {
        window.UIkit.modal('#media-picker-modal').show();
      });
    },
    closeModal () {
      window.UIkit.modal('#media-picker-modal').hide();
      // this.showPicker = false;
      this.dontClearForm = true;
      window.UIkit.modal('#prepare-ad-modal').show();
      this.$nextTick(() => {
        this.dontClearForm = false;
      });
    },
    clearForm () {
      this.selectedType = 'video';
      if( this.$route.query.ad ) {
        const url = new URL(window.location);
        url.searchParams.set('ad', '');
        window.history.replaceState({}, '', url);
      }
      for (let key in this.form) {
        this.errors[key] = key === 'deepLinks' ? this.errors.deepLinks : false;
        switch (key) {
          case 'tags': this.form[key] = []
            break;
          case 'projection': this.form[key] = 'EQUIRECTANGULAR'
            break;
          case 'deepLinks': for (let device of this.devices) this.form.deepLinks[device] = { uri: '', bundleId: '' } ;
            break;
          default: this.form[key] = ''
        }
      }
    },
    validateForm () {
      let isValid = true
      const fieldList = this[this.type]
      for (const key in fieldList) {
        if (key === 'deepLinks') {
          for (let deepLink in this.errors.deepLinks) {
            if (this.errors.deepLinks[deepLink]) {
              isValid = false
            }
            if (this.form.deepLinks[deepLink].bundleId) {
              if (!this.form.deepLinks[deepLink].uri) {
                isValid = false
                this.errors.deepLinks[deepLink] = this.$t('Pages.Advertising.Labels.FixDeepLinks')
                this.toggleDeepLinkErrors(deepLink, true)
              }
            } else if (!this.form.deepLinks[deepLink].uri && this.errors.deepLinks[deepLink]) {
              this.errors.deepLinks[deepLink] = false
              this.toggleDeepLinkErrors(deepLink, false)
            }
          }
        } else if (fieldList[key]) {
          if (this.errors[key]) {
            isValid = false
          } else if(!this.form[key]) {
            this.errors[key] = this.$t('Generic.Errors.MandatoryField')
            isValid = false
          }
        }
      }
      return isValid
    },
    addTag () {
      this.form.tag = this.form.tag.replace(' ', '')
      if (this.form.tag.length < 2) return
      if(!this.form.tags.includes(this.form.tag) && this.form.tag.length >= 2)
        this.form.tags.push(this.form.tag);
      this.form.tag = '';
    },
    deleteTag (tag) {
      this.form.tags = this.form.tags.filter(item => item !== tag )
    },
    capitalizeFirstLetter(string='') {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    saveAd () {
      if (this.isUpdate) {
        this.updateAd()
      } else {
        this.createAd()
      }
      this.dontClearForm = false
    },
    async updateAd () {
      if (this.isLoading) return
      if (!this.validateForm()) return
      this.isSaving = true
      const params = this.getAdparams()
      const response = await this.$store.dispatch('updateAd', params)
      if (response?.ad) {
        Notification.showNotification(this.$t('Pages.Advertising.Notifications.AdUpdated'), this.$t('Pages.Advertising.Notifications.AdUpdatedDesc').replace('{{type}}', this.capitalizeFirstLetter(this.type)))
        this.fetchAds(false);
        this.isSaving = false
        window.UIkit.modal('#prepare-ad-modal').hide();
        this.clearForm()
      } else {
        this.isSaving = false
        Notification.showNotification(this.$t('Pages.Advertising.Notifications.AdNotUpdated'), (response?.errors[0]?.message) || response, 'error')
      }
      return true
    },
    async createAd () {
      if (this.isSaving) return
      if (!this.validateForm()) return
      this.isSaving = true
      const params = this.getAdparams()
      const response = await this.$store.dispatch('createAd', params)
      if (response?.ad) {
        Notification.showNotification(this.$t('Pages.Advertising.Notifications.AdCreated'), this.$t('Pages.Advertising.Notifications.AdCreatedDesc').replace('{{type}}', this.capitalizeFirstLetter(this.type)))
        await this.fetchAds(false)
        this.isSaving = false
        window.UIkit.modal('#prepare-ad-modal').hide();
        this.clearForm()
      } else {
        this.isSaving = false
        Notification.showNotification(this.$t('Pages.Advertising.Notifications.AdNotCreated'), (response?.errors[0]?.message) || response, 'error')
      }
      return true
    },
    getAdparams () {
      let params = {}
      if (this.isUpdate) {
        params.ad = this.adId
        if (this.type === 'video') {
          const video = this.form
          params.projection = video.projection
          if (video.video?.id) {
            params.videoMedia = {
              id: video.video.id
            }
          }
          if (video.logo?.id) {
            params.logoMedia = {
              id: video.logo.id
            }
          }
          if (video.keyart?.id) {
            params.bannerMedia = {
              id: video.keyart.id
            }
          }
        } else if (this.type === 'small-banner') {
          const sb = this.form
          params.destinationUrl = sb.destinationURL
          if (sb.banner?.id) {
            params.bannerMedia = {
              id: sb.banner.id
            }
          }
        } else if (this.type === 'medium-banner') {
          const mb = this.form
          params.destinationUrl = mb.destinationURL
          if (mb.banner?.id) {
            params.bannerMedia = {
              id: mb.banner.id
            }
          }
          if (mb.logo?.id) {
            params.logoMedia = {
              id: mb.logo.id
            }
          }
        } else if (this.type === 'promoted') {
          const promoted = this.form
          params.attribution = promoted.attribution
          params.description = promoted.description
          params.projection = promoted.projection
          if (promoted.video?.id) {
            params.videoMedia = {
              id: promoted.video.id
            }
          }
          if (promoted.keyart?.id) {
            params.bannerMedia = {
              id: promoted.keyart.id
            }
          }
          if (promoted.logo?.id) {
            params.logoMedia = {
              id: promoted.logo.id
            }
          }
          const deepLinks = []
          for (const key in promoted.deepLinks) {
            deepLinks.push({
              platform: key,
              uri: promoted.deepLinks[key].uri || '',
              bundleId: promoted.deepLinks[key].bundleId || ''
            })
          }
          if (deepLinks.length) {
            params.deepLinks = deepLinks
          }
        }
      } else {
        if (this.type === 'video') {
          const video = this.form
          params = {
            projection: video.projection,
            videoMedia: {
              id: video.video?.id
            }
          }
          if (video.logo) {
            params.logoMedia = {
              id: video.logo?.id
            }
          }
          if (video.keyart) {
            params.bannerMedia = {
              id: video.keyart?.id
            }
          }
        } else if (this.type === 'small-banner') {
          const sb = this.form
          params = {
            bannerMedia: {
              id: sb.banner?.id
            },
            destinationUrl: sb.destinationURL
          }
        } else if (this.type === 'medium-banner') {
          const mb = this.form
          params = {
            bannerMedia: {
              id: mb.banner?.id
            },
            logoMedia: {
              id: mb.logo?.id
            },
            destinationUrl: mb.destinationURL
          }
        } else if (this.type === 'promoted') {
          const promoted = this.form
          params = {
            attribution: promoted.attribution,
            projection: promoted.projection,
            bannerMedia: {
              id: promoted.keyart?.id
            },
            description: promoted.description
          }
          if (promoted.video) {
            params.videoMedia = {
              id: promoted.video.id
            }
          }
          if (promoted.logo) {
            params.logoMedia = {
              id: promoted.logo.id
            }
          }
          const deepLinks = []
          for (const key in this.form.deepLinks) {
            deepLinks.push({
              platform: key,
              uri: promoted.deepLinks[key].uri,
              bundleId: promoted.deepLinks[key].bundleId
            })
          }
          if (deepLinks.length) {
            params.deepLinks = deepLinks
          }
        }
        params.organization = this.orgId
      }
      params.name = this.form.title
      params.tags = this.form.tags
      return params
    },
    keyartUploadComplete (files) {
      this.isLoading = false
      const file = files[files.length -1]
      this.form.keyart = file.path
      this.form.keyartPreview = file.previewUrl
      this.$children[0].startedFiles = [file];
      this.$children[0].allFiles = this.$children[0].startedFiles;
    },
    logoUploadComplete (files) {
      this.isLoading = false
      const file = files[files.length -1]
      this.form.logo = file.path
      this.form.logoPreview = file.previewUrl
      this.$children[1].startedFiles = [file];
      this.$children[1].allFiles = this.$children[0].startedFiles;
    },
    videoUploadComplete (files) {
      this.isLoading = false
      const file = files[files.length -1]
      this.form.video = file.path
      this.form.videoPreview = file.previewUrl
      this.$children[2].startedFiles = [file];
      this.$children[2].allFiles = this.$children[0].startedFiles;
    },
  }
}
</script>

<style scoped>
  .image-placeholder-min-height {
    min-height: 150px;
  }
  .image-label-margin {
    margin-top: 66px;
  }
  .custom-button-padding {
    padding-left: 20px;
    padding-right: 20px;
  }
</style>